import React from "react";
import { FaHandshake } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";

const WhyChooseUs = () => {
  return (
    <div class="container mx-auto max-w-5xl flex gap-7 lg:gap-12 flex-wrap items-start justify-center md:justify-between py-20">
      <h2 className="text-center w-full text-[32px] font-bold">
        {" "}
        چرا اکسپرینس ریلیتی؟
      </h2>
      <div class="grid gap-2 lg:gap-4 justify-items-center text-center md:flex-1">
        <div class=" rounded-full border-8 border-green-500 p-4 ">
          <MdOutlineSupportAgent size={56} />
        </div>
        <h3 class="text-xl lg:text-3xl font-bold">پشتیبانی 24 ساعته</h3>
        <p>در هر زمانی از روز یا شب در خدمت شما هستیم</p>
      </div>
      <div class="grid gap-2 lg:gap-4 justify-items-center text-center md:flex-1">
        <div class=" rounded-full border-8 border-green-500 p-4 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-14 h-14"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
            ></path>
          </svg>
        </div>
        <h3 class="text-xl lg:text-3xl font-bold">ضمانت خرید</h3>
        <p>با خرید از ما لازم نیست نگران چیزی باشید</p>
      </div>
      <div class="grid gap-2 lg:gap-4 justify-items-center text-center md:flex-1">
        <div class=" rounded-full border-8 border-green-500 p-4 ">
            <FaHandshake size={56}/>
        </div>
        <h3 class="text-xl lg:text-3xl font-bold">همکاری</h3>
        <p>شرکت ما افتخار همکاری با شرکت های نا آشنایی را داشته است</p>
      </div>
    </div>
  );
};

export default WhyChooseUs;
