import ProjectCard from "components/common/ProjectCard";
import Contact from "components/Contact";
import Footer from "components/Footer";
import Header from "components/header";
import { Example_data } from "data/example";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaBed, FaDollarSign, FaLocationDot, FaUser } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ProjectDetailPage = () => {
  const [data, setData] = useState();

  const { company, name } = useParams();

  useEffect(() => {
    const filtered = Example_data.find((item) => item.url == name);
    setData(filtered);
  }, [name]);

  return (
    <div className="bg-black">
      <Header />
      <Helmet>
        <title>{`X Realty | ${data?.name}`}</title>
        <meta name="og:title" content={`X Realty | ${data?.name}`} />
        <meta name="og:description" content={data?.desc} />
        <meta name="description" content={data?.desc} />
      </Helmet>
      <div dir="rtl" className="main pt-[76px] lg:pt-[110px]">
        <Swiper
          modules={[Pagination, Autoplay]}
          loop
          autoplay={{ delay: 3000 }}
          pagination
        >
          {data?.images.map((item) => (
            <SwiperSlide>
              <img
                src={item}
                className="w-full object-cover lg:h-[80vh]"
                alt={data?.name}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <section dir="rtl" id="service-details" class=" section">
          <div class="container mb-5">
            <h1 className="text-[24px] lg:text-[28px] font-bold">
              {data?.name}
            </h1>
            <h2 className="mt-1 text-[20px] lg:text-[22px] text-black/60">
              {data?.desc}
            </h2>
            <p className="mt-4 text-[14px] lg:text-[16px] text-black/80">
              {data?.more_desc}
            </p>
            <div className="flex flex-col md:flex-row gap-3 items-center mt-4 flex-wrap">
              <span className="border p-4 flex items-center gap-2 text-[18px] w-full md:w-fit lg:text-[20px]">
                <FaBed size={25} /> {data?.type} {data?.bed} خوابه
              </span>
              <span className="border p-4 flex items-center gap-2 text-[18px] w-full md:w-fit lg:text-[20px]">
                <FaDollarSign size={25} /> شروع قیمت از {data?.price} درهم
              </span>
              <span className="border p-4 flex items-center gap-2 text-[18px] w-full md:w-fit lg:text-[20px]">
                <FaUser size={25} /> شرکت سازنده {data?.company}
              </span>
              <span className="border p-4 flex items-center gap-2 text-[18px] w-full md:w-fit lg:text-[20px]">
                <FaLocationDot size={25} /> {data?.location}
              </span>
            </div>
          </div>
          <Contact />
        </section>

        <iframe
          title="location"
          src={data?.iframe}
          width={"100%"}
          height={600}
        />
      </div>
      <Footer />
    </div>
  );
};

export default ProjectDetailPage;
