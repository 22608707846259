import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";

const clients = [
  "/client (1).jpg",
  "/client (2).jpg",
  "/client (3).jpg",
  "/client (4).jpg",
  "/client (5).jpg",
  "/client (6).jpg",
  "/client (1).png",
];

const About = ({noname}) => {
  return (
    <>
      <section id="about" class={`${noname && "pt-0 pb-[1rem]"} about section`}>
        <div style={{ zIndex: "5", position: "relative" }} class="container">
          <div class="row gy-4">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <video
                src="/video2.mp4"
                class="img-fluid rounded-2 mb-4 mb-lg-0 shadow w-full max-w-[380px] mx-auto"
                alt=""
                controls
              />
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="250">
              <div dir="rtl" class="content ps-0 ps-lg-5 section-title">
                <span class="description-title ">درباره ما</span>
                <h2 className="text-[32px]">درباره ما</h2>
                <p className="text-[16px] lg:text-[18px]">
                  در دنیایی که دارایی ها داستان هایی برای گفتن دارند، ما اینجا
                  هستیم تا شما را در فصل های فرصت، رشد و تحول راهنمایی کنیم. تیم
                  ما از مشاوران املاک و مستغلات که با دقت انتخاب شده اند متعهد
                  هستند که رویاهای شما را به واقعیت تبدیل کنند. ما از اصل رونق
                  مداوم استفاده می کنیم و یک برنامه درسی جامع از دانش املاک و
                  مستغلات ارائه می دهیم که شما را برای حرکت در چشم انداز پویای
                  صنعت مجهز می کند. تخصص ما فراتر از مرزهای یک شرکت معمولی است.
                  ما به شما این شانس را می دهیم که در دنیای املاک و مستغلات برتر
                  شوید. همانطور که خود را در طیف گسترده بینش ما غرق می کنید،
                  متوجه می شوید که در معاملات تغییر دهنده بازی شرکت می کنید که
                  آینده شما را شکل می دهد.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!noname && (

      <section id="clients" class="clients section light-background">
        <div dir="rtl" class=" section-title m-0">
          <span class="description-title ">شریک های تجاری ما</span>
          <h2>شریک های تجاری ما</h2>
        </div>
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <Swiper
            autoplay={{ delay: 4000 }}
            modules={[Autoplay, Pagination]}
            loop
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            }}
          >
            {clients.map((item) => (
              <SwiperSlide>
                <img src={item} class="img-fluid" alt="" />
              </SwiperSlide>
            ))}

            <div class="swiper-pagination"></div>
          </Swiper>
        </div>
      </section>
      )}
    </>
  );
};

export default About;
